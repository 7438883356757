import { Box, Button, FormControl, FormLabel, Heading,Input,Stack, Center, Alert, AlertIcon, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const NewSecteur = () => {
    const [secteurNom, setSecteurNom] = useState('');
    const [secteurLoc, setSecteurLoc] = useState('');
    const [error, setError] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!secteurNom || !secteurLoc) {
        setError('Veuillez remplir tous les champs');
        return;
      }
      try {
        const response = await axios.post( `${process.env.REACT_APP_API_URL_}/secteurs/`, {
          secteur_nom : secteurNom,
          secteur_localisation : secteurLoc
        });
        if (response.status === 201) {
          setSecteurNom('');
          setSecteurLoc('');
          setError('');

        } else {
          throw new Error('Une erreur est survenue lors de la création du secteur');
        }
        toast({
          title: "Succès !",
          description: "Le secteur a bien été créé.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        //Redirige l'utilisateur vers la page du secteur créé
        navigate(`/secteurs/${response.data.secteur_id}`);
      } catch (error) {
        setError(error.message);
      }
    };

    return (
          <form onSubmit={handleSubmit}>
            <Box
              p="4"
              maxW="md"
              borderWidth="1px"
              borderRadius="md"
              boxShadow="md"
              bg="white"
              mx="auto"
            >
              <Center mb={5}>
              <Heading> Nouveau Secteur </Heading>
              </Center>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Nom</FormLabel>
                  <Input 
                  type="text"
                  value={secteurNom}
                  onChange={(e) => setSecteurNom(e.target.value)}
                  />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Ville</FormLabel>
                    <Input
                    type="text"
                    value={secteurLoc}
                    onChange={(e) => setSecteurLoc(e.target.value)}
                    />
                    </FormControl>
                    {error && (
            <Alert mt={3}status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
                    <Button type="submit" colorScheme="blue">
                      Créer le secteur
                    </Button>
                    
              </Stack>
          </Box>
        </form>
    );
};



export default NewSecteur;
