import React from 'react';
import { Box, Button, Center, Heading } from '@chakra-ui/react';
import UserForm from './UserForm';
import AdminCheckbox from './AdminCheckbox';

const InviteUserForm = ({ user, setUser, handleInvite }) => {
  return (
    <Box
      maxW="md"
      mx="auto"
      mt="8"
      p="8"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
    >
      <Center mb={5}>
        <Heading>Inviter un utilisateur</Heading>
      </Center>
      <form>
        <UserForm user={user} setUser={setUser} />
        <AdminCheckbox user={user} setUser={setUser} />
        <Center mt={5}>
          <Button colorScheme="blue" onClick={handleInvite}>
            Envoyer l'invitation
          </Button>
        </Center>
      </form>
    </Box>
  );
};

export default InviteUserForm;
