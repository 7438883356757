
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import NewSecteur from './components/Secteur/NewSecteur';
import InfoSecteur from './components/Secteur/InfoSecteur';
import InfoLycee from './components/Lycee/InfoLycee';
import Connexion from './pages/LoginPage';
import SignupPage from './pages/SignUpPage';
import ProfilePage from './components/Profil/ProfilPage';
import ContactPage from './pages/ContactPage';
import PrivateRoutes from './components/Routes/PrivateRoutes';
import Perm from './components/Perm';
import Synthese from './components/Synthese';
import SearchContent from './components/Search/index';
import { Image } from '@chakra-ui/react';
import SanterneTriangle from './assets/SanterneTriangle.png';
import InfoIntervention from './components/Intervention/InfoInter';
import CompleteInvitationPage from './components/Connexion/CompleteInvitationPage';
import ForgotPassword from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPasswordPage';
import Invitation from './components/Invitation/Invitation';
import Home from './components/Homepage/Home';
import PageNotFound from './pages/PageNotFound';
import MentionsLegales from './pages/Conditions';

function App() { 
  return (
    <BrowserRouter>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ position: 'fixed', bottom: '0%', left: '0%' }}>
          <Image
            src={SanterneTriangle}
            alt="Santerne Fluides"
            w="175px"
          />
        </div>
      <NavBar/>
      <Routes>
        <Route path='/newSecteur' element={<PrivateRoutes> <NewSecteur/> </PrivateRoutes>}/>
        <Route path='/secteurs/:id' element={<PrivateRoutes> <InfoSecteur/> </PrivateRoutes>}/>
        <Route path='/lycee/:id' element={<PrivateRoutes><InfoLycee/></PrivateRoutes>}/>
        <Route path='/intervention/:id' element={<PrivateRoutes><InfoIntervention/></PrivateRoutes>}/>
        <Route path='/Connexion' element={<Connexion/>}/>
        <Route path='/auth/complete-invitation' element={<CompleteInvitationPage/>} />
        <Route path='/Signup' element={<SignupPage/>}/>
        <Route path='/Profil' element={<PrivateRoutes><ProfilePage/> </PrivateRoutes>}/>
        <Route path='/Contact' element={<ContactPage/>}/>
        <Route path='/Permission' element={<PrivateRoutes><Perm/> </PrivateRoutes>}/>
        <Route path='/Synthese' element={<PrivateRoutes><Synthese/></PrivateRoutes>}/>
        <Route path='/Search' element={<PrivateRoutes><SearchContent/></PrivateRoutes>}/>
        <Route path='/ForgotPassword' element={<ForgotPassword/>}/>
        <Route path='/auth/reset-password' element={<ResetPassword/>}/>
        <Route path='/users/invite' element={<PrivateRoutes><Invitation/></PrivateRoutes>}/>
        <Route path='/' element={<Home/>}/>
        <Route path='/MentionsLegales' element={<MentionsLegales/>}/>
        <Route path='*' element={<PageNotFound/>}/>
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
