import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  AccordionIcon,
  Box,
  Button,
  Tfoot,
  Heading,
} from "@chakra-ui/react";
import ModalNewPerm from "./newPerm";
import { AddIcon, DeleteIcon, MinusIcon } from "@chakra-ui/icons";
import './styles/style.css'
import ModalNewReferent from "./newRef";
import { useAuth } from "../../context/AuthContext";

const Perm = () => {
  const [synthese, setSynthese] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {isAdmin} = useAuth(); 

  const fetchSynthese = async () => {
    setLoading(true);
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL_}/synthese/`);
      setSynthese(response.data);
    } catch (error) {
      console.error("Une erreur est survenue", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSynthese();
  }, []);

  const getAccessLevelLabel = (accessLevel) => {
    switch (accessLevel) {
      case 1:
        return "Lecture seul";
      case 2:
        return "Modification";
        case 5:
            return "Référent";
      default:
        return "Inconnu";
    }
  };

  const handleDeletePerm = async (id) => {
    if(loading) return;
    if (id) {
      try {
        await axios.delete( `${process.env.REACT_APP_API_URL_}/perms/${id}`);
        fetchSynthese();
        
      } catch (error) {
        console.error("Une erreur est survenue lors de la suppression de la perm :", error);
        setError("Une erreur est survenue lors de la suppression de la permission.");
      }
    }
  };

  const handleAddPerm = async (id, ac) => {
    if (loading) return;
    if (id) {
      try {
        await axios.put( `${process.env.REACT_APP_API_URL_}/addperm/${id}`, { accessLevel: ac }); 
        fetchSynthese();
      } catch (error) {
        console.error("Une erreur est survenue lors de l'ajout de la perm :", error);
        setError("Une erreur est survenue lors de la création de la permission.");
      }
    }
  };
  

const handleDownPerm = async (id, ac) => {
    if(loading) return;
    if (id) {
        try {
            await axios.put( `${process.env.REACT_APP_API_URL_}/downperm/${id}`, { accessLevel: ac });
            fetchSynthese();
        } catch (error) {
            console.error("Une erreur est survenue lors de l'ajout de la perm :", error);
            setError("Une erreur est survenue lors de la création de la permission.");
        }
    }
};
  
  
  let content;
  if (loading) {
    content = <p>Chargement en cours...</p>;
  } else if (isAdmin) {
    content = (
      <Box boxSize="4xl" ml="auto" mr="auto">
        <Accordion allowMultiple>
          {Object.keys(synthese).map((secteur, index) => {
            const secteurKey = `secteur-${index}`;
            return (
              <AccordionItem key={secteurKey}>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Secteur : {secteur}
                      <AccordionIcon ml={2} />
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>Lycée</Th>
                          <Th>User</Th>
                          <Th>Niveau d'accès</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {synthese[secteur].map((lycee, lyceeKey) => {
                          const lyceeItemKey = `lycee-${lyceeKey}`;
                          return (
                            lycee.accessLevel === 5 ? (
                              <Tr key={lyceeItemKey} bg={"orange.200"}>
                                <Td fontWeight={"bold"}>{lycee.lycee}</Td>
                                <Td>{lycee.user}</Td>
                                <Td justifyContent={"center"} display={"flex"} borderColor={"transparent"}>{getAccessLevelLabel(lycee.accessLevel)}</Td>
                                <Td justifyContent={"center"} display={"flex"}>
                                  <Button onClick={() => handleDeletePerm(lycee.permission_id)} variant='ghost'> <DeleteIcon /></Button>
                                </Td>
                              </Tr>
                            ) : (
                              <Tr key={lyceeItemKey}
                                _even={{ bg: 'white' }} 
                                _odd={{ bg: 'gray.100' }} 
                              >
                                <Td fontWeight="bold">{lycee.lycee}</Td>
                                <Td>{lycee.user}</Td>
                                <Td justifyContent={"center"} display={"flex"} borderColor={"transparent"}>{getAccessLevelLabel(lycee.accessLevel)}</Td>
                                <Td justifyContent={"center"} display={"flex"} >
                                  <Button mr={5} onClick={() => handleDownPerm(lycee.permission_id, lycee.accessLevel)} isDisabled={lycee.accessLevel === 1} variant='ghost'> <MinusIcon /></Button>
                                  <Button mr={5} onClick={() => handleAddPerm(lycee.permission_id, lycee.accessLevel)} isDisabled={lycee.accessLevel === 2} variant='ghost'> <AddIcon /></Button>
                                  <Button onClick={() => handleDeletePerm(lycee.permission_id)} variant='ghost'> <DeleteIcon /></Button>
                                </Td>
                              </Tr>
                            )
                          );
                        })}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Lycée</Th>
                          <Th>User</Th>
                          <Th>Niveau d'accès</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
        {isAdmin ? <ModalNewPerm /> : null}
        {isAdmin ? <ModalNewReferent /> : null}
        {error && <p>{error}</p>}
      </Box>
    );
  } else {
    content = (
      <Box textAlign="center" mt={5} fontSize="lg">
        Vous n'avez pas accès à cette page.
      </Box>
    );
  }

  return (
    <>
      <Heading textAlign="center" mt={5} mb={5}>
        Gestion des permissions
      </Heading>
      {content}
    </>
  );

};

export default Perm;
