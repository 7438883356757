import { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Heading, Box, Center, Card, CardBody, Text, Divider, CardFooter, SimpleGrid } from "@chakra-ui/react";
import axios from "axios";
import SuppressionLyceeModal from "../Lycee/SuppressionLyceeModal";
import SuppressionSecteurModal from "./SuppressionSecteurModal";
import CreateLyceeDrawer from "../Lycee/CreateLyceeDrawer";
import ProgressBar from "../ProgressBar";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const InfoSecteur = () => {
  const { id } = useParams();
  const [secteur, setSecteur] = useState([]);
  const [lycees, setLycees] = useState([]);
  const [accept,setAccept] = useState(0);
  const [refus, setRefus] = useState(0);
  const [pending, setPending] = useState(0);
  const {isAdmin} = useAuth();
  const navigate = useNavigate();


  const fetchSecteur = useCallback(async () => {
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL_}/secteurs/${id}`);
      setSecteur(response.data);
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [id]);

  const fetchLycee = useCallback(async () => {
    try {
      if (isAdmin) {
      const response = await axios.get( `${process.env.REACT_APP_API_URL_}/secteur/${id}/lycee`);
      setLycees(response.data);
      }else{
        const response = await axios.get( `${process.env.REACT_APP_API_URL_}/secteur/${id}/lyceeNoAdmin`, {params : {"user_id": JSON.parse(localStorage.getItem("user")).user_id}});
        setLycees(response.data);
      }
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [id, isAdmin]);

  const handleLyceeCreated = (newLycee) => {
    setLycees((prevLycees) => [...prevLycees, newLycee]);
  };

  const handleDeleteSuccess = () => {
    navigate(`/`);
    
  };

  const fetchStat = useCallback(async () => {
    try{
      const response = await axios.get( `${process.env.REACT_APP_API_URL_}/docs_stats`);
        setAccept(response.data["secteurs"]?.[`${secteur.secteur_nom} - ${secteur.secteur_localisation}`]?.['Accepté'] || 0);
        setRefus(response.data["secteurs"]?.[`${secteur.secteur_nom} - ${secteur.secteur_localisation}`]?.['Refusé'] || 0);
        setPending(response.data["secteurs"]?.[`${secteur.secteur_nom} - ${secteur.secteur_localisation}`]?.['En attente'] || 0);
      }catch(error){
      console.error("Une erreur est survenue", error)
    }
  }, [secteur]) // Added an empty array as the second argument to useCallback
  

  useEffect(() => {
    fetchSecteur();
    fetchLycee();
    fetchStat();
  }, [fetchSecteur, fetchLycee, fetchStat]);

  return (
    <Box w={{ base: "90%", md: "60%" }} bg="blackAlpha.100" ml="auto" mr="auto" paddingBottom={50}>
  <Center mt={5} mb={5}>
    <Heading>
      {secteur.secteur_nom} - {secteur.secteur_localisation}
    </Heading>
  </Center>
  {lycees.length === 0 ? (
    <Box textAlign="center" mt={5} fontSize="lg">
      Aucun lycée disponible pour ce secteur.
    </Box>
  ) : (
    <Center>
      <SimpleGrid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={3} ml={"auto"} mr={"auto"}>
        {lycees.map((lycee) => (
          <Card w="250px" h="200px" key={lycee.lycee_id} m={5}>
            <Link to={`/lycee/${lycee.lycee_id}`}>
              <CardBody>
                  <Center>
                    <Heading size="md">{lycee.name}</Heading>
                  </Center>
                  <Text textAlign={"center"} h={"50px"} >
                    {lycee.adress1}{lycee.adress2 !== "" ? `, ${lycee.adress2}` : ""},  {lycee.postalcode} {lycee.city}
                  </Text>
              </CardBody>
            </Link>
            {isAdmin ? (
              <>
                <Divider />
                <CardFooter ml={"auto"} mr={"auto"}>

                  <SuppressionLyceeModal lyceeId={String(lycee.lycee_id)} onDeleteSuccess={handleDeleteSuccess} />
                </CardFooter>
              </>
            ) : null}
          </Card>
        ))}
      </SimpleGrid>
    </Center>
  )}

  <Box mt={5} fontSize="md" ml={5} mr={5}>
    <ProgressBar 
    accepted={accept} 
    pending={pending} 
    rejected={refus}/>
  </Box>
  {isAdmin ? (
    <>
      <Center>
        <CreateLyceeDrawer secteurId={id} onLyceeCreated={handleLyceeCreated} />
      </Center>
      <Center>
        <SuppressionSecteurModal secteurId={id} onDeleteSuccess={handleDeleteSuccess} />
      </Center>
    </>
  ) : (null)}
</Box>

  );
};

export default InfoSecteur;
