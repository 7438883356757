import React from "react";
import { Box, Button, Center, Heading } from "@chakra-ui/react";
import PasswordInput from "./PasswordInput";

const ResetPasswordForm = ({ password, setPassword, confirmPassword, setConfirmPassword, onSubmit }) => {
  return (
    <Box maxWidth="400px" mx="auto" mt="50px" p="4" borderWidth="1px" borderRadius="md">
      <form onSubmit={onSubmit}>
        <Center mb={5}>
          <Heading>Réinitialiser le mot de passe</Heading>
        </Center>
        <PasswordInput label="Nouveau mot de passe" password={password} setPassword={setPassword} />
        <PasswordInput label="Confirmer le mot de passe" password={confirmPassword} setPassword={setConfirmPassword} />
        <Center mt={5}>
          <Button type="submit">Réinitialiser le mot de passe</Button>
        </Center>
      </form>
    </Box>
  );
};

export default ResetPasswordForm;
