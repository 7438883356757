import React, {useRef} from "react";
import {
  Box,
  Center,
  Avatar,
  Heading,
  Text,
  Divider,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import PasswordChangeModal from "../Connexion/PasswordChangeModal";

const ProfilePage = () => {
  const { user,isAdmin } = useAuth();
  const modalRef = useRef();

  const openModal = () => {
    if(modalRef.current) modalRef.current.openModal();
  };

  return (
    <>
      <Box
        p="4"
        maxW={{ base: "md", md: "2xl" }} // Responsive width
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        bg="white"
        mx="auto"
      >
        <Center mb={4}>
          <Avatar name={`${user.first_name} ${user.last_name}`} size="xl" />
        </Center>
        <Center mb={4}>
          <Heading size="lg">
            {user.first_name} {user.last_name}
          </Heading>
        </Center>
        <Center mb={2}>
          <Text>{user.email}</Text>
        </Center>
        <Center mb={2}>
          <Text>{user.company}</Text>
        </Center>
        <Divider mt={4} mb={4} />
        <Center>
          
            <Button mr={2} colorScheme="blue" onClick={openModal}>
              Modifier le mot de passe
            </Button>
            <PasswordChangeModal ref={modalRef} />
          <Link to="/DeleteCompte">
            <Button colorScheme="red">Supprimer Compte</Button>
          </Link>
        </Center>
      </Box>
      {isAdmin ? (
        <>
        <Center mt={4}>
          <Button mt={4} colorScheme="blue">
            <Link to="/Permission">Gérer les permissions</Link>
          </Button>
        </Center>
        <Center>
          <Button mt={4} bg="orange.500" color="white">
            <Link to="/users/invite">Gérer les utilisateurs invités</Link>
          </Button>
        </Center>
        <Center>
          <Button mt={4} >
            <Link to="/Signup">Ajouter un utilisateur </Link>
          </Button>
        </Center>
        </>
      ) : null}
    </>
  );
};

export default ProfilePage;
