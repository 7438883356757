import React from 'react';
import { Pie } from 'react-chartjs-2';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';


ChartJS.register(ArcElement, Tooltip, Legend);


const ProgressBar = ({ accepted, pending, rejected }) => {


  const data = {
    labels: ['Documents acceptés', 'Documents en attente', 'Documents refusés'],
    datasets: [
      {
        data: [accepted, pending, rejected],
        backgroundColor: ['#68D391', '#CBD5E0', '#E53E3E'],
        hoverBackgroundColor: ['#68D391', '#CBD5E0', '#E53E3E'],
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    },
    animation: {
      duration: 0,
    },
  };


  return (
    <Box w='300px' ml={'auto'} mr={'auto'}>
      <Pie data={data} options={options}/>
    </Box>
  );
};

ProgressBar.propTypes = {
  accepted: PropTypes.number.isRequired,
  pending: PropTypes.number.isRequired,
  rejected: PropTypes.number.isRequired,
};
export default ProgressBar;
