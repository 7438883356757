import React from "react";
import { CardFooter, ButtonGroup, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, ModalFooter, useDisclosure, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import axios from "axios";
import { showSuccessToast } from "../../utils/toastNotifications";

const InterventionControls = ({ intervention, isAdmin, fetchInterventions }) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const handleChangeEtat = async (InterId, etat) => {
    try {
      if (etat <= 3 && etat >= -1) {
        console.log("Changement d'état de l'intervention en cours...");
        await axios.put(`${process.env.REACT_APP_API_URL_}/intervention/${InterId}/status`, {
          interventions_etat: etat,
        });
        fetchInterventions();
      }
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  };

  const renderChangeEtatButton = (id, etat) => {
    return (
      <Button onClick={() => handleChangeEtat(id, etat + 1)} colorScheme="blue">
        Modifier l'état
      </Button>
    )
  }

  const handleDeleteIntervention = async (id) => {
    try {
      console.log("Suppression de l'intervention en cours...");
      await axios.delete(`${process.env.REACT_APP_API_URL_}/intervention/${id}`);
      showSuccessToast(toast, "Intervention supprimée avec succès");
      fetchInterventions();
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }

  const renderDeleteButton = (id) => {
    return (
      <>
      <Button onClick={onOpen} colorScheme="red">
        Supprimer Intervention
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Supprimer Intervention</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Êtes-vous sûr de vouloir supprimer cette intervention ?</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="red" onClick={() => handleDeleteIntervention(id)}>
              Supprimer Intervention
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
    )
  }

  const renderAcceptInterButton = (id) => {
    return (
      <Button onClick={()=> handleChangeEtat(id, 0)} colorScheme="green">
        Accepter Intervention
      </Button>
    )
  }

  const renderRefuseInterButton = (id) => {
    return (
      <>
        <Button onClick={onOpen} colorScheme="red">
          Refuser Intervention
        </Button>
        {/* Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Refuser Intervention</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Etes-vous sur de refuser cette intervention, l'intervention sera supprimée.</Text>
            </ModalBody>
            <ModalFooter>
              <Button  mr={3} onClick={onClose}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={() => handleDeleteIntervention(id)}>
                Refuser Intervention
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  

  return (
    <CardFooter ml="auto" mr="auto">
      {intervention.intervention_etat === -1 ? (
        isAdmin && (
          <ButtonGroup>
            {renderAcceptInterButton(intervention.intervention_id)}
            {renderRefuseInterButton(intervention.intervention_id)}
          </ButtonGroup>          
        )
      ): (
      <ButtonGroup>
        {isAdmin && (
          <>
            {renderChangeEtatButton(intervention.intervention_id, intervention.intervention_etat)}
            {renderDeleteButton(intervention.intervention_id)}
          </>
        )}
        {intervention.intervention_etat !== -1 && (
        <Button>
          <Link to={`/Intervention/${intervention.intervention_id}`}>Consulter des documents</Link>
        </Button>
        )}
      </ButtonGroup>

      )}
    </CardFooter>
      
  );
};

export default InterventionControls;
