import React from 'react';
import { FormControl, Checkbox } from '@chakra-ui/react';

const AdminCheckbox = ({ user, setUser }) => {
  return (
    <FormControl mt={5}>
      <Checkbox
        isChecked={user.admin}
        onChange={(e) => setUser({ ...user, admin: e.target.checked })}
      >
        Rôle admin
      </Checkbox>
    </FormControl>
  );
};

export default AdminCheckbox;
