import React, { useEffect, useState } from 'react'
import { Button, Center, Select, useDisclosure, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useToast } from '@chakra-ui/react'
import axios from 'axios';
function ModalNewPerm() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userID, setUserID] = useState("");
    const [lyceeID, setLyceeID] = useState("");
    const [accessLevel, setAccessLevel] = useState(0);
    const [error, setError] = useState("");
    const toast = useToast();

    const [users, setUsers] = useState([]);
    const [lycees, setLycees] = useState([]);

    

    useEffect(() => {
        const fetchLycee = async () => {
            try {
                const response = await axios.get( `${process.env.REACT_APP_API_URL_}/lycees/`);
                setLycees(response.data);
            } catch (error) {
                toast({
                    title: "Erreur",
                    description: "Une erreur est survenue lors de la récupération des lycées.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        const fetchUser = async () => {
            try {
                const response = await axios.get( `${process.env.REACT_APP_API_URL_}/users/`);
                setUsers(response.data.filter(user => user.role === "user"));
                
            } catch (error) {
                toast({
                    title: "Erreur",
                    description: "Une erreur est survenue lors de la récupération des utilisateurs.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
    
            }
        };
    
        fetchUser();
        fetchLycee();
    }, [toast]);

    const handleCreatePerm = async () => {
        try {
            await axios.post( `${process.env.REACT_APP_API_URL_}/perms/`, {user_id : userID, lycee_id :lyceeID, access_level : accessLevel});
            toast({
                title: "Permission ajoutée",
                description: "La permission a bien été ajoutée.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error("Une erreur est survenue lors de l'ajout de la perm :", error);
            setError("Une erreur est survenue lors de la création de la permission.");
        }
    };




    return (
      <>
      <Center>
        <Button mt={5} onClick={onOpen}>Ajouter une permission</Button>
        </Center>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ajout d'une permission</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <FormLabel>Utilisateur</FormLabel>
                <Select mb={3} onChange={e => setUserID(e.target.value)}>
                    <option value="option1">Choix de l'user</option>
                    {users.map((user) => (
                        <option key={user.user_id} value={user.user_id}>{user.email}</option>
                    ))}
                </Select>
                <FormLabel>Lycée</FormLabel>
                <Select mb={3} onChange={e => setLyceeID(e.target.value)}>
                    <option value="option1">Choix du lycée</option>
                    {lycees.map((lycee) => (
                        <option key={lycee.lycee_id} value={lycee.lycee_id}>{lycee.name}</option>
                    ))}
                </Select>
                <FormLabel>Niveau d'accès</FormLabel>
                <Select onChange={(e)=>setAccessLevel(e.target.value)}>
                    <option value="1">Lecture seule</option>
                    <option value="2">Modification</option>
                </Select>
                {error && <p>{error}</p>}
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                Annuler
              </Button>
              <Button variant='ghost' onClick={handleCreatePerm}>Valider</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
  export default ModalNewPerm;
