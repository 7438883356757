import React from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  FormLabel,
  Heading,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import PasswordInput from "./PasswordInput";

const LoginForm = ({ onSubmit, email, setEmail, password, setPassword }) => {

  return (
    <form onSubmit={onSubmit}>
      <Box
        p="4"
        maxW="md"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        bg="white"
        mx="auto"
      >
        <Center mb={5}>
          <Heading>Connexion</Heading>
        </Center>
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="username"
          />

          <PasswordInput label="Mot de passe" password={password} setPassword={setPassword} />
          
          <Center mt={5}>
            <Link to="/ForgotPassword">
              <Button variant="link"> Mot de passe oublié ?</Button>
            </Link>
          </Center>

          <Center mt={5}>
            <Button type="submit">Connexion</Button>
          </Center>
        </FormControl>
      </Box>
    </form>
  );
};

export default LoginForm;
