import { useState } from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Spacer,
  useToast,
  Switch,
} from "@chakra-ui/react";
import axios from "axios";
import PropTypes from "prop-types";
import { useAuth } from "../../context/AuthContext";
import { formatDate } from "../../utils/formatters";

const CreateDocDrawer = ({ interId, onDocCreated, clientMail, refMail }) => {
  const today = new Date();
  const formattedToday = today.toISOString(); // Format correct pour PostgreSQL

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, isAdmin } = useAuth();
  const toaster = useToast();

  // Initialisation de l'état avec la valeur de clientMail et refMail
  const [dataForm, setDataForm] = useState({
    name: "",
    intervention_id: interId,
    sending_date: formattedToday,
    desired_date: "",
    file: null,
    category: "",
    created_by: user.user_id,
    status: 0,
    isAdmin: isAdmin,
  });

  const handleCreateDoc = async () => {

    // Validation de la date souhaitée
    const desiredDate = new Date(dataForm.desired_date);
    if (isNaN(desiredDate.getTime())) {
      toaster({
        title: "Erreur",
        description: "Veuillez fournir une date souhaitée valide",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    // Validation des autres champs
    if (dataForm.name === "" || dataForm.file === null) {
      toaster({
        title: "Erreur",
        description: "Veuillez remplir tous les champs",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', dataForm.name);
      formData.append('intervention_id', dataForm.intervention_id);
      formData.append('sending_date', dataForm.sending_date);
      formData.append('desired_date', dataForm.desired_date);
      formData.append('file', dataForm.file);
      formData.append('category', dataForm.category);
      formData.append('created_by', dataForm.created_by);
      formData.append('status', dataForm.status);
      formData.append('clientMail', clientMail);
      formData.append('refMail', refMail);
      formData.append('isAdmin', dataForm.isAdmin);
    
      const response = await axios.post(`${process.env.REACT_APP_API_URL_}/docs/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    
      onDocCreated(response.data);
    
      toaster({
        title: "Document créé",
        description: dataForm.status === 5
          ? "Le document a bien été créé et masqué aux clients"
          : "Le document a bien été créé",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    
      onClose();
      resetForm();
    } catch (error) {
      console.error("Une erreur est survenue lors de la création du document :", error);
    }    
  };

  const resetForm = () => {
    setDataForm({
      name: "",
      intervention_id: interId,
      sending_date: formattedToday,
      desired_date: "",
      file: null,
      category: "",
      created_by: user.user_id,
      status: 0,
      cliMail: clientMail,
      refMail: refMail,
      isAdmin: isAdmin,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDataForm((prevDataForm) => ({
      ...prevDataForm,
      [name]: type === "checkbox" ? (checked ? 5 : 0) : value,
    }));
  };

  const handleFileChange = (e) => {
    setDataForm((prevDataForm) => ({
      ...prevDataForm,
      file: e.target.files[0],
    }));
  };

  return (
    <>
      <Button mt={5} mb={5} variant="solid" colorScheme="blue" onClick={onOpen}>
        Créer Document
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Ajout d'un document</DrawerHeader>
          <Text fontSize="sm" color="gray.500" ml={5} mt={2}>
            Mail client : {clientMail} / Référence : {refMail}
          </Text>
          <DrawerBody>
            <FormControl mt={4} isRequired>
              <FormLabel>Nom</FormLabel>
              <Input name="name" value={dataForm.name} onChange={handleChange} required />
            </FormControl>

            <FormControl mt={4} isReadOnly>
              <FormLabel>Date d'envoi</FormLabel>
              {/* Date au format dd/mm/yyyy */}
              <Input name="sending_date" value={formatDate(today)} variant="filled" />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Catégorie</FormLabel>
              <Select name="category" value={dataForm.category} onChange={handleChange}>
                <option value="Devis">Devis</option>
                <option value="Facture">Facture</option>
                <option value="Bon de commande">Bon de commande</option>
                <option value="Note de calculs">Note de calculs</option>
                <option value="Plan">Plan</option>
                <option value="Schéma de principe">Schéma de principe</option>
                <option value="Autre">Autre</option>
              </Select>
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Date souhaitée</FormLabel>
              <Input name="desired_date" value={dataForm.desired_date} onChange={handleChange} type="date" />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Importer un fichier</FormLabel>
              <Input type="file" onChange={handleFileChange} />
              <Text color="#da6e36">Extensions acceptées : pdf, jpeg, png, jpg, docx, xlsx, pptx</Text>
              <Text color="#244472">Taille max : 20Mo</Text>
            </FormControl>

            {isAdmin && (
              <FormControl display="flex" mt={4}>
                <FormLabel>Masquer aux clients</FormLabel>
                <Switch id="etat" name="status" isChecked={dataForm.status === 5} onChange={handleChange} />
              </FormControl>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Spacer />
            <Button variant="ghost" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="blue" onClick={handleCreateDoc}>
              Créer
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

CreateDocDrawer.propTypes = {
  interId: PropTypes.string.isRequired,
  onDocCreated: PropTypes.func.isRequired,
  clientMail: PropTypes.string,
  refMail: PropTypes.string,
};

export default CreateDocDrawer;
