import React, { useState } from 'react';
import {
    Button,

    ModalBody,
    VStack,
    useToast,
} from '@chakra-ui/react';
import PasswordInput from './PasswordInput'; // Import du PasswordInput
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { validatePassword, validatePasswordMatch } from '../../utils/passwordValidation'; // Import des validations
import { showErrorToast } from '../../utils/toastNotifications';

const PasswordChangeForm = ({ onClose }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const toast = useToast();
    const { user } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePasswordMatch(newPassword, confirmPassword)) {
            showErrorToast(toast, 'Les mots de passe ne correspondent pas.');
            return;
        }

        if (!validatePassword(newPassword)) {
            showErrorToast(toast, "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, et un chiffre.");
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL_}/users/changePassword`, {
                user_id: user.user_id,
                oldPassword: currentPassword,
                newPassword: newPassword,
            });
            toast({
                title: 'Succès !',
                description: 'Le mot de passe a été changé.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setConfirmPassword('');
            setCurrentPassword('');
            setNewPassword('');
            onClose();
        } catch (error) {
            toast({
                title: 'Erreur',
                description: error.response?.data?.message || 'Une erreur est survenue.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <ModalBody>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4} align="stretch">
                    <PasswordInput
                        label="Mot de passe actuel"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        isRequired
                    />
                    <PasswordInput
                        label="Nouveau mot de passe"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        isRequired
                    />
                    <PasswordInput
                        label="Confirmer le mot de passe"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        isRequired
                    />
                    <Button colorScheme="blue" type="submit">
                        Enregistrer
                    </Button>
                </VStack>
            </form>
        </ModalBody>
    );
};

export default PasswordChangeForm;
