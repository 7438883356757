import { useState, useCallback } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useToast } from "@chakra-ui/react";
import { showErrorToast, showSuccessToast } from "../utils/toastNotifications";

export const useInterventionData = (id) => {
  const [inter, setInter] = useState([]);
  const [docs, setDocs] = useState([]);
  const [droit, setDroit] = useState(0);
  const [accept, setAccept] = useState(0);
  const [refus, setRefus] = useState(0);
  const [pending, setPending] = useState(0);
  const [referents, setReferents] = useState([]);
  const [client, setClient] = useState([]);
  const [mailRef, setMailRef] = useState("");
  const [mailClient, setMailClient] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user.user_id;
  const isAdmin = useAuth().isAdmin;
  const toast = useToast();

  
  const fetchInter = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/intervention/${id}`);
      setInter(response.data);

      if (!isAdmin && inter.lycee_id) {
        const perm = await axios.get(`${process.env.REACT_APP_API_URL_}/user/${userID}/lycee/${inter.lycee_id}/perm`);
        setDroit(perm.data[0].access_level);
      }

      const response2 = await axios.get(`${process.env.REACT_APP_API_URL_}/inter/docs_stats/${id}`);
      setAccept(response2.data["Accepté"] || 0);
      setRefus(response2.data["Refusé"] || 0);
      setPending(response2.data["En attente"] || 0);
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [id, userID, inter.lycee_id, isAdmin]);

  const fetchDoc = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/intervention/${id}/docs`);
      setDocs(response.data.docs);
      
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [id]);

  const fetchCorrespondant = useCallback(async (idI) => {
    if (!idI) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/lycee/${idI}/correspondant`);

      setClient(response.data.client || []);
      setReferents(response.data.referents || []);

      if (response.data.referents) {
        const ref = response.data.referents.map(p => p.User?.email || '');
        setMailRef(ref.join(", "));
      }
      if (response.data.client) {
        const cli = response.data.client.map(p => p.User?.email || '');
        setMailClient(cli.join(", "));
      }

    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, []);

  const recupMail = (obj = []) => {
    const mail = obj.map(p => p.User?.email || '');
    return mail.join(", ");
  }



  const handleDocCreated = (newDoc) => {
    setDocs((prevDocs) => [...prevDocs, newDoc]);
  };

  const sendConfirmation= async(mail, docID)=>{
    try {

      const response = await axios.post( `${process.env.REACT_APP_API_URL_}/mail_Confirmation`, {receiver :  mail, doc_id : docID});
      console.log(response.data)
      if(response.status===200){
        showSuccessToast(toast, `Mail envoyé à ${mail}`);
      }
    }catch (error) {
      showErrorToast(toast, "Une erreur est survenue");
    } 
  }

  const envoiMail = async (user, docID) => {
    sendConfirmation(user.email, docID)
  }


  const handleRefuse = async (docId, comment, creator) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL_}/docs/${docId}/status`, {
        status: -1,
        comment: comment,
        approuved_by : userID,
      });
      fetchDoc();
      if(isAdmin){
        sendConfirmation(creator.email)
      }else{
        sendConfirmation(mailRef)
      }
    } catch (error) {
      showErrorToast(toast, "Une erreur est survenue");
    }
  };

  const handleChangeStatus = async (docId, status, creator) => {
    try {
      if(status === 1){
      await axios.put(`${process.env.REACT_APP_API_URL_}/docs/${docId}/status`, {
          status: status,
          approuved_by: userID,
        });
        }
        fetchDoc();
        if(isAdmin){
          sendConfirmation(creator.email)
        }else{
          sendConfirmation(mailRef)
        }
      }catch (error) {
        console.error("Une erreur est survenue", error);
      }
  };
  const handleDeleteSuccess = () => {
    // Traitement supplémentaire après suppression réussie
  };

  const  handleShowDoc = async (docId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL_}/docs/${docId}/status`, {
        status: 0,
      });
      fetchDoc();
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  };

  return {
    inter,
    docs,
    droit,
    accept,
    refus,
    pending,
    referents,
    client,
    mailRef,
    mailClient,
    fetchCorrespondant,
    fetchInter,
    fetchDoc,
    handleDocCreated,
    handleDeleteSuccess,
    handleRefuse,
    handleChangeStatus,
    handleShowDoc,
    envoiMail,
    recupMail,
  };
};
