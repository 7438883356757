import React from 'react';
import { Box, Button, Center, Heading, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';

const ForgotPasswordForm = ({ email, setEmail, onSubmit }) => {
  return (
    <Box
      p="4"
      maxW="md"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      bg="white"
      mx="auto"
    >
      <form onSubmit={onSubmit}>
        <Center mb={5}>
          <Heading>Mot de passe oublié</Heading>
        </Center>
        <VStack spacing={4} align="stretch">
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>
          <Center mt={4}>
            <Button type="submit">Réinitialiser le mot de passe</Button>
          </Center>
        </VStack>
      </form>
    </Box>
  );
};

export default ForgotPasswordForm;
