// Fonction de vérification des mots de passe
export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return passwordRegex.test(password);
};

// Fonction de vérification de la confirmation des mots de passe
export const validatePasswordMatch = (password, confirmPassword) => {
    return password === confirmPassword;
};
