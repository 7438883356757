import { DownloadIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import axios from 'axios';
import PropTypes from 'prop-types';

const FileDownload = ({ docId, fileName }) => {
    const handleDownload = async () => {
        try {
            const res = await axios.get( `${process.env.REACT_APP_API_URL_}/docs/${docId}/download` , {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (ex) {
            console.log(ex);
        }
    };

    return (
        <Button onClick={handleDownload} leftIcon={<DownloadIcon />}>
            Télécharger document
        </Button>
    );
};

FileDownload.propTypes = {
    docId: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
};

export default FileDownload;
