import React from 'react';
import { Box, Flex, Image, Heading, Text } from '@chakra-ui/react';
import locaux from './../../assets/locaux.jpg';

const Home = () => {
    return (
        <Flex direction={['column', 'row']} mt={10} mx="auto" maxW="1200px">
            <Box flex={['1', '0.5']} ml={['0', '10%']} mb={['10px', '0']}>
                <Heading as="h1" size="xl" pt={10}>Bienvenue</Heading>
                <Text pt={10}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc id aliquam lacinia, elit nunc
                    ullamcorper nunc, ut consectetur nunc nunc ac nunc. Sed euismod, nunc id aliquam lacinia, elit nunc
                    ullamcorper nunc, ut consectetur nunc nunc ac nunc.
                </Text>
            </Box>
            <Box flex={['1', '0.5']} ml={['0', '10%']} mr={['0', '10%']} mt={['10px', '0']}>
                <Image src={locaux} alt="Image d'accueil" h={['200px', '300px']} />
            </Box>
        </Flex>
    );
};

export default Home;
