import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LoginForm from "../components/Connexion/LoginForm";
import { showErrorToast, showSuccessToast } from "../utils/toastNotifications";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      showErrorToast(toast, "Veuillez remplir tous les champs.");
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_}/users/login`, {
        email,
        password,
      });
      if (response.status === 200) {
        showSuccessToast(toast, "Vous êtes connecté.");

        const token = response.data.token;
        localStorage.setItem("token", token);
        login(response.data.user);
        setEmail("");
        setPassword("");
        navigate("/");
      }
    } catch (error) {
      showErrorToast(toast, error.response?.status === 404
         ? "La connexion a échoué. Vérifiez votre email et mot de passe." 
         : "Une erreur est survenue lors de la connexion.");
    }
  };

  return (
    <LoginForm
      onSubmit={handleSubmit}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
    />
  );
};

export default LoginPage;
