import { useState } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, useToast } from "@chakra-ui/react";
import axios from "axios";
import PropTypes from "prop-types";
import { showErrorToast, showSuccessToast } from "../../utils/toastNotifications";

const SuppressionLyceeModal = ({ lyceeId, onDeleteSuccess }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const handleDelete = async () => {
    setIsLoading(true);
    
    try {
      // Appel à l'API pour supprimer le lycée
      await axios.delete( `${process.env.REACT_APP_API_URL_}/lycees/${lyceeId}`);
      // Traitement supplémentaire après suppression réussie
      showSuccessToast(toast, "Lycée supprimé avec succès");
      onDeleteSuccess();
      onClose();
      
    } catch (error) {
      console.error("Une erreur est survenue lors de la suppression du lycée:", error);
      showErrorToast(toast, "Une erreur est survenue lors de la suppression du lycée");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Button  variant="solid" colorScheme="red" onClick={onOpen}>
        Supprimer Lycée
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation de suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer ce lycée ?
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Annuler
            </Button>
            <Button colorScheme="red" onClick={handleDelete} isLoading={isLoading}>
              Supprimer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

SuppressionLyceeModal.propTypes = {
  lyceeId: PropTypes.string.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
};

export default SuppressionLyceeModal;
