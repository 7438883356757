import React, { forwardRef, useImperativeHandle } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import PasswordChangeForm from './PasswordChangeForm'; // Import du PasswordChangeForm

const PasswordChangeModal = forwardRef((props, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(ref, () => ({
        openModal: onOpen,
        closeModal: onClose,
    }));

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent mt={150}>
                <ModalHeader>Changement du mot de passe</ModalHeader>
                <ModalCloseButton />
                <PasswordChangeForm onClose={onClose} />
                <Button mt={2} mr="auto" ml="auto" mb={3} onClick={onClose} w='90%' >Annuler</Button>
            </ModalContent>
        </Modal>
    );
});

export default PasswordChangeModal;
