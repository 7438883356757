import { Box, Text } from "@chakra-ui/react";

function MentionsLegales() {
    return (
        <Box>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
                Mentions légales
            </Text>
            <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc id aliquam lacinia, elit nunc
                    ullamcorper nunc, ut consectetur nunc nunc ac nunc. Sed euismod, nunc id aliquam lacinia, elit nunc
                    ullamcorper nunc, ut consectetur nunc nunc ac nunc.
            </Text>
        </Box>
    );
}

export default MentionsLegales;