import { useState } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import PropTypes from "prop-types";

const SuppressionDocModal = ({ docId, onDeleteSuccess }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      // Appel à l'API pour supprimer le document 
      await axios.delete( `${process.env.REACT_APP_API_URL_}/docs/${docId}` );
      // Traitement supplémentaire après suppression réussie
      onDeleteSuccess();
      onClose();
    } catch (error) {
      console.error("Une erreur est survenue lors de la suppression du document:", error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Button variant="solid" colorScheme="red" onClick={onOpen}>
        Supprimer Document
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation de suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer ce document ?
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Annuler
            </Button>
            <Button colorScheme="red" onClick={handleDelete} isLoading={isLoading}>
              Supprimer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

SuppressionDocModal.propTypes = {
  docId: PropTypes.number.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
};

export default SuppressionDocModal;
