import React, { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import ForgotPasswordForm from '../components/Connexion/ForgotPasswordForm';
import { showErrorToast, showSuccessToast } from '../utils/toastNotifications';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/forgotPassword`, { email });

      if (response.status === 200) {
        showSuccessToast(toast, 'Un email de réinitialisation a été envoyé.');
      }
    } catch (error) {
      showErrorToast(toast, error.response?.status === 404
        ? "Cet email n'existe pas."
        : "Une erreur est survenue lors de l'envoi de l'email de réinitialisation.");
    }
    };

  return (
    <ForgotPasswordForm email={email} setEmail={setEmail} onSubmit={handleSubmit} />
  );
};

export default ForgotPassword;
