import React from "react";
import { Card, CardBody, CardFooter, Stack, ButtonGroup, Button, Box, Center, Divider, Heading, Text } from "@chakra-ui/react";
import FileDownload from "./FileDownload";
import SuppressionDocModal from "./SuppressionDocModal";
import RefusStatus from "./RefusStatus";
import { formatDate } from "../../utils/formatDate";
import { isImageFile } from "../../utils/isImageFile";
import { EmailIcon } from "@chakra-ui/icons";
import { useAuth } from "../../context/AuthContext";
import { estAdmin } from "../../utils/estAdmin";
import propTypes from "prop-types";



const DocumentCard = ({ doc, droit, handleDeleteSuccess, handleRefuse, handleShowDoc, handleChangeStatus, envoiMail }) => {


  const isAdmin = useAuth().isAdmin;

  const getStatusText = (doc) => {
    let statusText = '';
    switch (doc.status) {
      case -1:
        statusText = 'Refusé';
        if (doc.approuved_by) {
          statusText += ` par ${doc.ApprovedBy.first_name} ${doc.ApprovedBy.last_name}`;
        }
        if (doc.approuved_date) {
          statusText += ` le ${formatDate(doc.approuved_date)}`;
        }
        return statusText;
      case 0:
        return 'En attente';
      case 1:
        statusText = 'Accepté';
        if (doc.approuved_by) {
          statusText += ` par ${doc.ApprovedBy.first_name} ${doc.ApprovedBy.last_name}`;
        }
        if (doc.approuved_date) {
          statusText += ` le ${formatDate(doc.approuved_date)}`;
        }
        return statusText;
      case 5:
        return 'Masqué';
      default:
        return '';
    }
  };

  const renderCategory = (doc) => {
    if (doc.category) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" mt="6" fontWeight="normal">
          <Heading size="sm" marginRight="2">Catégorie :</Heading>
          {doc.category}
        </Box>
      );
    }
    return null;
  };

  const renderDesiredDate = (doc) => {
    if (doc.desired_date) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" mt="6" fontWeight="normal">
          <Heading size="sm" marginRight="2">Date voulu :</Heading>
          {formatDate(doc.desired_date)}
        </Box>
      );
    }
    return null;
  };

  const renderAdminButtons = (doc, isAdmin, droit, handleChangeStatus, handleRefuse) => {
    if (doc.ApprovedBy) {
      return null;
    }
    if (estAdmin(doc.CreatedBy?.role)){
      if(droit === 2) {
      return (
        <ButtonGroup>
          <Button onClick={() => handleChangeStatus(doc.document_id, 1, doc.CreatedBy)}>Valider</Button>
          <RefusStatus docId={doc.document_id} onRefuse={handleRefuse} creator={doc.CreatedBy} />
        </ButtonGroup>
      );
    }
      return null;
    } else {
      if (isAdmin) {
      return (
        <ButtonGroup>
          <Button onClick={() => handleChangeStatus(doc.document_id, 1, doc.CreatedBy)}>Valider</Button>
          <RefusStatus docId={doc.document_id} onRefuse={handleRefuse} creator={doc.CreatedBy} />
        </ButtonGroup>
      );
    }
  }
    return null;
  };

  const renderComment = (doc) => {
    if (doc.comment) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" mt="6" fontWeight="normal">
          <Heading size="sm" marginRight="2">Commentaire :</Heading>
          {doc.comment}
        </Box>
      );
    }
    return null;
  };

  const renderFilePreview = (doc) => {
    if (isImageFile(doc.file_path)) {
      return (
        <Center mt={4}>
          <img src={`${process.env.REACT_APP_API_URL_}/uploads/${doc.file_path}`} alt={doc.name} width={300} />
        </Center>
      );
    }
    return null;
  };

  const renderFooterButtons = (doc, isAdmin, handleShowDoc, handleDeleteSuccess, envoiMail) => {
    if (isAdmin) {
      return (
        <Stack spacing={4}>
          {doc.status === 5 && (
            <Box textAlign="center">
              <Button onClick={() => handleShowDoc(doc.document_id)}>Afficher le document</Button>
            </Box>
          )}
          <Box>
            <ButtonGroup>
              <FileDownload fileName={doc.file_path} docId={doc.document_id} />
              <SuppressionDocModal docId={doc.document_id} onDeleteSuccess={handleDeleteSuccess} />
              <Button onClick={() => envoiMail(doc.CreatedBy, doc.document_id)}>
                <EmailIcon mr={2} />
                Rappeler
              </Button>
            </ButtonGroup>
          </Box>
        </Stack>
      );
    } else {
      return (
        <CardFooter alignContent="center">
          <Box textAlign="center" mr="auto" ml="auto">
            <FileDownload fileName={doc.file_path} docId={doc.document_id} mr="auto" ml="auto" />
          </Box>
        </CardFooter>
      );
    }
  };

  const statusText = getStatusText(doc);
  const backgroundImage = doc.status === 5 
    ? 'repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.05) 0px, rgba(0, 0, 0, 0.05) 10px, rgba(255, 255, 255, 0.05) 10px, rgba(255, 255, 255, 0.05) 20px)' 
    : '';

  return (
    (!isAdmin && doc.status === 5) ? null : (
    <Card maxW="xl" mx="auto" mb={5} backgroundImage={backgroundImage}>
      
      <CardBody>

        <Stack mt="2" spacing="3">
          <Center>
            <Heading size="md">{doc.name}</Heading>
          </Center>
          <Box display="flex" alignItems="center" justifyContent="center" mt="6" fontWeight="normal">
            <Heading size="sm" marginRight="2">Date :</Heading>
            {formatDate(doc.sending_date)}
            <Heading size="sm" marginRight="2" marginLeft="2">Créé par :</Heading>
            {doc?.CreatedBy?.last_name} {doc.CreatedBy?.first_name}
          </Box>
          {renderCategory(doc)}
          {renderDesiredDate(doc)}
          <Box display="flex" alignItems="center" justifyContent="center" mt="6" fontWeight="normal">
            <Heading size="sm" marginRight="2">État :</Heading>
            {statusText}
          </Box>
          {renderAdminButtons(doc, isAdmin, droit, handleChangeStatus, handleRefuse)}
          {renderComment(doc)}
          {renderFilePreview(doc)}
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        {renderFooterButtons(doc, isAdmin, handleShowDoc, handleDeleteSuccess, envoiMail)}
      </CardFooter>
    </Card>
  ))};

DocumentCard.propTypes = {
  doc: propTypes.object.isRequired,
  droit: propTypes.number.isRequired,
  handleDeleteSuccess: propTypes.func.isRequired,
  handleRefuse: propTypes.func.isRequired,
  handleShowDoc: propTypes.func.isRequired,
  handleChangeStatus: propTypes.func.isRequired,
  envoiMail: propTypes.func.isRequired,
  mailRef: propTypes.string,
  mailClient: propTypes.string,
};

export default DocumentCard;
