import { Box, Button, Flex, Image, Spacer, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { AddIcon, SearchIcon, HamburgerIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logoSanterne from "../assets/logoSanterne.png";
import { useAuth } from "../context/AuthContext"; 

const NavBar = () => {
  const [secteurs, setSecteurs] = useState([]);
  const { isLogged, isAdmin, logout } = useAuth(); 

  useEffect(() => {
    fetchSecteurs();
  }, [secteurs]);

  const fetchSecteurs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/secteurs/`);
      setSecteurs(response.data);
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  };

  const handleLogout = () => {
    logout(); 
  };

  return (
    <Box pt={6} pl={6} pr={6} pb={6}>
      <Flex align="center">
        <Link to="/">
          <Image w="240px" src={logoSanterne} alt="Santerne Fluides" mr={10} />
        </Link>

        {/* Hamburger menu for smaller screens */}
        <Menu>
          <MenuButton as={Button} variant="link" fontSize={18} color="#343c51" display={{ base: "block", md: "none" }}>
            <HamburgerIcon boxSize={6} />
          </MenuButton>
          <MenuList>
            {secteurs.map((secteur) => (
              <Link key={secteur.secteur_id} to={`/secteurs/${secteur.secteur_id}`}>
                <MenuItem>{`${secteur.secteur_nom} - ${secteur.secteur_localisation}`}</MenuItem>
              </Link>
            ))}
            {isAdmin ? (
              <Link to="/newSecteur">
                <MenuItem>
                  Ajouter un secteur
                </MenuItem>
              </Link>
            ) : (
              <Link to={`/Contact`}>
                <MenuItem>Contact</MenuItem>
              </Link>
            )}
            <Link to="/Synthese">
              <MenuItem>Synthèse</MenuItem>
            </Link>
            {isLogged ? (
              <>
                <Link to="/Search">
                  <MenuItem>
                    Recherche
                  </MenuItem>
                </Link>
                <Link to="/Profil">
                  <MenuItem>Profil</MenuItem>
                </Link>
                <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
              </>
            ) : (
              <Link to="/Connexion">
                <MenuItem>Connexion</MenuItem>
              </Link>
            )}
          </MenuList>
        </Menu>

        {/* Regular navigation for larger screens */}
        <Box display={{ base: "none", md: "block" }}>
          {secteurs.map((secteur) => (
            <Link key={secteur.secteur_id} to={`/secteurs/${secteur.secteur_id}`}>
              <Button pt={0.5} mr={8} mt={10} variant="link" fontSize={18} color="#2c4575">
                {`${secteur.secteur_nom} - ${secteur.secteur_localisation}`}
              </Button>
            </Link>
          ))}
          {isAdmin ? (
            <Link to="/newSecteur">
              <AddIcon pt={0.5} mr={8} mt={-1} color="#2c4575" boxSize={5} />
            </Link>
          ) : (
            <Link to={`/Contact`}>
              <Button pt={0.5} mr={8} mt={10} variant="link" fontSize={18} color="#2c4575">
                {`Contact`}
              </Button>
            </Link>
          )}
          <Link to="/Synthese">
            <Button pt={0.5} mr={8} mt={10} variant="link" fontSize={18} color="#2c4575">
              {`Synthèse`}
            </Button>
          </Link>
          <Spacer />
        </Box>
      </Flex>

      <Flex justify="flex-end" mt={-10} display={{ base: "none", md: "flex" }}>
        {isLogged ? (
          <>
            <Link to="/Search">
              <SearchIcon pt={0.5} mr={4} color="#343c51" boxSize={5} />
            </Link>
            <Link to="/Profil">
              <Button mr={4} mt={0} bg="blue.100">
                Profil
              </Button>
            </Link>
            <Button mt={0} bg="gray.600" color="white" onClick={handleLogout}>
              Déconnexion
            </Button>
          </>
        ) : (
          <Link to="/Connexion">
            <Button mr={8} mt={0} bg="orange.500" color="white">
              Connexion
            </Button>
          </Link>
        )}
      </Flex>
    </Box>
  );
};

export default NavBar;
