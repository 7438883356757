import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import PropTypes from "prop-types";

const PrivateRoutes = ({ children }) => {
  const { isLogged, logout } = useAuth(); // Utilisation de isLogged et logout depuis le contexte

  useEffect(() => {
    if (!isLogged) {
      logout(); // Déconnexion si l'utilisateur n'est pas authentifié
    }
  }, [isLogged, logout]);

  return isLogged ? children : <Navigate to="/connexion" />;
};

PrivateRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoutes;
