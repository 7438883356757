import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

const RefusStatus = ({ docId, onRefuse, creator}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refuseComment, setRefuseComment] = useState("");


  const handleRefuse = () => {
    console.log("refuse : ", creator)
    onRefuse(docId, refuseComment, creator);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen}>Refuser</Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={undefined} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Refuser le document
            </AlertDialogHeader>
            <AlertDialogBody>
              Entrez la raison du refus
              <Input
                value={refuseComment}
                onChange={(e) => setRefuseComment(e.target.value)}
                placeholder="Raison du refus"
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>Annuler</Button>
              <Button colorScheme="red" onClick={handleRefuse} ml={3}>
                Refuser
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

RefusStatus.propTypes = {
  docId: PropTypes.number.isRequired,
  onRefuse: PropTypes.func.isRequired,
  creator: PropTypes.object,
};

export default RefusStatus;
