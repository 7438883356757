import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const PageNotFound = () => {
    return (
        <Box textAlign="center" >
            <Heading as="h1" size="xl" mb={8}>
                Page non trouvée
            </Heading>
            <Text fontSize="xl">Désolé, la page que vous recherchez est introuvable.</Text>
        </Box>
    );
};

export default PageNotFound;