import React, { useEffect, useState } from "react";
import { Box, Center, Heading, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import InterventionCard from "../Intervention/InterCard";
import ProgressBar from "../ProgressBar";
import CreateInterDrawer from "../Intervention/CreateInterDrawer";
import SuppressionLyceeModal from "./SuppressionLyceeModal";
import { useFetchLycee } from "../../hooks/useFetchLycee";
import { useFetchInterventions } from "../../hooks/useFetchInter";
import { useFetchStats } from "../../hooks/useFetchStats";
import { useAuth } from "../../context/AuthContext";
import { SearchIcon } from "@chakra-ui/icons";
import DemandeInterventionCard from "../Intervention/DemandeInterCard";
import PopOverInfoLycee from "./PopOverInfoLycee.js";



const InfoLycee = () => {
  const { id } = useParams();
  const { lycee, fetchLycee, droit, fetchDroit, client, referents, fetchCorrespondant, recupMail} = useFetchLycee(id);
  const { interventions, fetchInterventions, handleInterCreated } = useFetchInterventions(id);
  const { accept, refus, pending, fetchStats } = useFetchStats(id);
  const isAdmin = useAuth().isAdmin;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [clientMail,setClientMail] = useState(""); 
  const [referentMail, setReferentMail] = useState("");

  const handleDeleteSuccess = () => {
    navigate(`/`);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtrer les interventions selon le terme de recherche
  const filteredInterventions = interventions.filter((intervention) =>
    intervention.intervention_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchLycee();
    fetchInterventions();
    fetchStats();
    fetchDroit();
    fetchCorrespondant(lycee.lycee_id);
    setClientMail(recupMail(client));
    setReferentMail(recupMail(referents));
  }, [fetchLycee, fetchInterventions, fetchStats, fetchDroit, fetchCorrespondant, lycee, client, referents, recupMail]);

  return (
    <Box w="70%" bg="blackAlpha.100" ml="auto" mr="auto" paddingBottom={50}>
      <Box pos="relative">
      <PopOverInfoLycee referent={referents} client={client} />

      <Center mt={5} mb={5} display="flex" flexDirection="column">
        <Heading>{lycee.name}</Heading>
        <Text as="b">{lycee.city}</Text>
      </Center>
      </Box>

      <Center mb={5}>
        <InputGroup w="50%" borderColor="black">
          <Input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Rechercher une intervention"
            textColor="black"
          />
          <InputRightElement>
            <SearchIcon />
          </InputRightElement>
        </InputGroup>
      </Center>

      {filteredInterventions.length === 0 ? (
        <Box textAlign="center" mt={5} fontSize="lg">
          Aucune intervention trouvée
        </Box>
      ) : (
        filteredInterventions.map((intervention) => (
          intervention.intervention_etat === -1 ? (
            <DemandeInterventionCard 
              key={intervention.intervention_id} 
              intervention={intervention} 
              isAdmin={isAdmin} 
              fetchInterventions={fetchInterventions} 
            />
          ) : (
          <InterventionCard
            key={intervention.intervention_id}
            intervention={intervention}
            isAdmin={isAdmin}
            fetchInterventions={fetchInterventions}
          />
          )
        ))
      )}

      {pending === 0 && refus === 0 && accept === 0 ? null : (
        <Box mt={5} fontSize="md" ml={5} mr={5}>
          <ProgressBar accepted={accept} pending={pending} rejected={refus} />
        </Box>
      )}

      {isAdmin && (
        <>
          <Center>
            <CreateInterDrawer lyceeId={id} onInterCreated={handleInterCreated} clientMail={clientMail} refMail={referentMail} />
          </Center>
          <Center>
            <SuppressionLyceeModal lyceeId={id} onDeleteSuccess={handleDeleteSuccess} />
          </Center>
        </>
      )}

      {droit === 2 && (
        <Center>
          <CreateInterDrawer lyceeId={id} onInterCreated={handleInterCreated} clientMail={clientMail} refMail={referentMail}/>
        </Center>
      )}



    </Box>
  );
};

export default InfoLycee;
