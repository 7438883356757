import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Button,
  Checkbox,
  Center,
  Link as ChakraLink,
  useToast,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import PasswordInput from './PasswordInput';
import { useCompleteInvitation } from '../../hooks/useCompleteInvitation';

const CompleteInvitationForm = () => {
  const toast = useToast();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    accord,
    setAccord,
    handleCompleteInvitation,
  } = useCompleteInvitation(toast);

  return (
    <form>
      <VStack spacing={4} align="stretch">
        <FormControl isRequired>
          <FormLabel>Prénom</FormLabel>
          <Input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Nom</FormLabel>
          <Input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormControl>

        <PasswordInput
          label="Mot de passe"
          value={password}
          setPassword={setPassword}
        />

        <PasswordInput
          label="Confirmer le mot de passe"
          value={confirmPassword}
          setPassword={setConfirmPassword}
        />

        <FormControl mt={5} isRequired>
          <Checkbox isChecked={accord} onChange={(e) => setAccord(e.target.checked)}>
            J'accepte les <ChakraLink as={ReactRouterLink} to='/MentionsLegales' color="#264576">conditions d'utilisation</ChakraLink>
          </Checkbox>
        </FormControl>

        <Center mt={5}>
          <Button
            colorScheme="blue"
            onClick={handleCompleteInvitation}
            isDisabled={!accord}
          >
            Compléter l'inscription
          </Button>
        </Center>
      </VStack>
    </form>
  );
};

export default CompleteInvitationForm;
