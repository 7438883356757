import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useInterventionData } from "../../hooks/useInterData";
import ProgressBar from "../ProgressBar";
import CreateDocumentDrawer from "../Docs/CreateDocumentDrawer";
import SuppressionLyceeModal from "../Lycee/SuppressionLyceeModal";
import DocumentCard from "../Docs/DocCard";
import { useAuth } from "../../context/AuthContext";
import { SearchIcon } from "@chakra-ui/icons";
import PopOverInfoLycee from "../Lycee/PopOverInfoLycee.js";

const InfoIntervention = () => {
  const { id } = useParams();
  const {
    inter,
    docs,
    droit,
    accept,
    refus,
    pending,
    referents,
    client,
    fetchInter,
    fetchDoc,
    fetchCorrespondant,
    handleDocCreated,
    handleDeleteSuccess,
    handleRefuse,
    handleChangeStatus,
    handleShowDoc,
    envoiMail,
    mailRef,
    mailClient,
  } = useInterventionData(id);
  const isAdmin = useAuth().isAdmin;
  const [sortOption, setSortOption] = useState("name");
  const [searchTerm, setSearchTerm] = useState("");


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  // Filtrer les documents en fonction du terme de recherche
  const filteredDocs = docs.filter((doc) =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Appliquer le tri après le filtrage
  if (sortOption === "name") {
    filteredDocs.sort((a, b) => a.name.localeCompare(b.name));
  } else if (sortOption === "date") {
    filteredDocs.sort((a, b) => new Date(b.sending_date) - new Date(a.sending_date));
  } else if (sortOption === "status") {
    filteredDocs.sort((a, b) => a.status - b.status);
  }

  useEffect(() => {
    fetchInter();
    fetchDoc();
    fetchCorrespondant(inter.lycee_id);
  }, [fetchInter, fetchDoc, fetchCorrespondant, inter, docs]);

  return (
    <Box w="70%" bg="blackAlpha.100" ml="auto" mr="auto" paddingBottom={50} pos="relative">
      {/* Container pour aligner le Popover et le Heading */}
      <Box pos="relative">
        {/* Popover positionné en haut à gauche */}
        <PopOverInfoLycee referent={referents} client={client} />

        {/* Heading centré */}
        <Center>
          <Heading mt={5}>{inter.intervention_name}</Heading>
        </Center>
      </Box>

      {/* Contenu principal */}
      <Center display={"flex"} flexDirection={"column"} mt={5} mb={5}>
        <Text fontSize="xl" mb={5}>
          {inter.intervention_description}
        </Text>
  
        <Select value={sortOption} onChange={handleSortChange} mb={5} w={"50%"} borderColor={"black"}>
          <option value="name">Trier par nom</option>
          <option value="date">Trier par date</option>
          <option value="status">Trier par état</option>
        </Select>
        <InputGroup w={"50%"} borderColor={"black"}>
          <Input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Rechercher un document"
            textColor={"black"}
          />
          <InputRightElement>
            <SearchIcon />
          </InputRightElement>
        </InputGroup>
      </Center>

      {filteredDocs.length === 0 ? (
        <Center>
          <Text>Aucun document disponible</Text>
        </Center>
      ) : (
        filteredDocs.map((doc) =>
          doc.status === 5 && !isAdmin ? null : (
            <DocumentCard
              key={doc.document_id}
              doc={doc}
              droit={droit}
              handleDeleteSuccess={handleDeleteSuccess}
              onDocCreated={handleDocCreated}
              handleShowDoc={handleShowDoc}
              handleChangeStatus={handleChangeStatus}
              handleRefuse={handleRefuse}
              envoiMail={envoiMail}
            />
          )
        )
      )}

      <Box mt={5} fontSize="md" ml={5} mr={5}>
        <ProgressBar accepted={accept} pending={pending} rejected={refus} />
      </Box>
      <Center>
        {(droit === 2 || isAdmin) && 
        <CreateDocumentDrawer interId={id} onDocCreated={handleDocCreated} clientMail={mailClient} refMail={mailRef}/>}
      </Center>
      <Center>
        {isAdmin && <SuppressionLyceeModal lyceeId={id} onDeleteSuccess={handleDeleteSuccess} />}
      </Center>
    </Box>
  );
};

export default InfoIntervention;
