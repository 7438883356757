import { useState } from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Spacer,
  useToast,
  Textarea,
  Switch,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import PropTypes from "prop-types";
import { useAuth } from "../../context/AuthContext";
import { showErrorToast, showSuccessToast } from "../../utils/toastNotifications";


const CreateInterDrawer = ({ lyceeId, onInterCreated, clientMail, refMail }) => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, isAdmin } = useAuth();

  const [dataForm, setDataForm] = useState({
    intervention_name: "",
    lycee_id: lyceeId,
    intervention_description: "",
    intervention_etat: isAdmin ? 0 : -1,
    intervention_numAffaire: "",
    intervention_numDevis: "",
    intervention_startdate: "",
    intervention_enddate: "",
    GPA : false, 
    BDC : "",
    Createur: user.user_id,
    cliMail: clientMail,
    refMail: refMail,
  });
  const toast = useToast();

  const handleCreateDoc = async () => { 
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_}/intervention`, dataForm,
        {headers: { "Content-Type": "application/json" }
      })
      if(isAdmin) { 
      showSuccessToast(toast, "Intervention créée avec succès");
      } else {
      showSuccessToast(toast, "Demande d'intervention créée avec succès. En attente de validation.");
      }
      onInterCreated(response.data);
      onClose();
    } catch (error) {
      showErrorToast(toast, "Une erreur est survenue lors de la création de l'intervention.");
    } 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataForm((prevDataForm) => ({
      ...prevDataForm,
      [name]: value,
    }));
  };

  const handleGpaChange = () => {
    setDataForm((prevDataForm) => ({
      ...prevDataForm,
      GPA: !prevDataForm.GPA,
    }));
  };

  return (
    <>
      <Button mt={5} mb={5} variant="solid" colorScheme="blue" onClick={onOpen}>
        Créer Intervention
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Ajout d'une Intervention</DrawerHeader>

          <DrawerBody>
            <form>
              <FormControl mt={4} isRequired>
                <FormLabel>Nom</FormLabel>
                <Input name="intervention_name" value={dataForm.intervention_name} onChange={handleChange} required />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea name="intervention_description" value={dataForm.intervention_description} onChange={handleChange} required />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Numéro d'affaire</FormLabel>
                <Input name="intervention_numAffaire" value={dataForm.intervention_numAffaire} onChange={handleChange} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Numéro de devis</FormLabel>
                <Input name="intervention_numDevis" value={dataForm.intervention_numDevis} onChange={handleChange} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Date de début estimé</FormLabel>
                <Input name="intervention_startdate" value={dataForm.intervention_startdate} onChange={handleChange} type="date" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Date de fin estimé</FormLabel>
                <Input name="intervention_enddate" value={dataForm.intervention_enddate} onChange={handleChange} type="date" />
              </FormControl>
              <FormControl mt={4} display="flex" alignItems="center">
                <Switch name="GPA" isChecked={dataForm.GPA} onChange={handleGpaChange}>GPA</Switch>
              </FormControl>

              {dataForm.GPA && (
                <FormControl mt={4}>
                  <FormLabel>BDC</FormLabel>
                  <Input name="BDC" value={dataForm.BDC} onChange={handleChange} />
                </FormControl>
              )}
            </form>
          </DrawerBody>
          <DrawerFooter>
            <Text>
              Mzil : {clientMail}
              Mail de référence : {refMail}
            </Text>
            <Spacer />
            <Button variant="ghost" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="blue" onClick={handleCreateDoc}>
              Créer
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

CreateInterDrawer.propTypes = {
  lyceeId: PropTypes.string.isRequired,
  onInterCreated: PropTypes.func.isRequired,
};

export default CreateInterDrawer;
