import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Text } from '@chakra-ui/react';
import React from 'react';

const PopOverInfoLycee = ({referent = [], client = []}) => {
    return (
        <Box pos="absolute" top={5} left={3}>
          <Popover>
            <PopoverTrigger>
              <InfoOutlineIcon boxSize={6} />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverHeader as="b">Informations</PopoverHeader>
              <PopoverBody>
                {referent.length !== 0 ? (
                  <>
                    <Text as="b">Référent :</Text>
                    {referent.map((ref, index) => (
                      <Text key={ref.user_id || index}>
                        {ref.User?.first_name || "Prénom indisponible"} {ref.User?.last_name || "Nom indisponible"}
                      </Text>
                    ))}
                  </>
                ) : (
                  <>
                    <Text as="b">Référent :</Text> <Text>Aucun</Text>
                  </>
                )}
                {client.length !== 0 ? (
                  <>
                    <Text as="b">Client :</Text>
                    {client.map((cli, index) => (
                      <Text key={cli.user_id || index}>
                        {cli.User?.first_name || "Prénom indisponible"} {cli.User?.last_name || "Nom indisponible"}
                      </Text>
                    ))}
                  </>
                ) : (
                  <>
                    <Text as="b">Client :</Text> <Text>Aucun</Text>
                  </>
                )}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
    );
};

export default PopOverInfoLycee;