import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../hooks/useQuery';
import { validatePassword, validatePasswordMatch } from '../utils/passwordValidation';
import { showErrorToast, showSuccessToast } from '../utils/toastNotifications';

export const useCompleteInvitation = (toast) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accord, setAccord] = useState(false);

  const navigate = useNavigate();
  const query = useQuery();
  const inviteToken = query.get('token');

  const handleCompleteInvitation = async () => {
    if (!firstName || !lastName || !password) {
      showErrorToast(toast, "Veuillez remplir tous les champs.");
      return;
    }
    if (!validatePasswordMatch(password, confirmPassword)) {
      showErrorToast(toast, "Les mots de passe ne correspondent pas.");
      return;
    }
    if (!validatePassword(password)) {
      showErrorToast(toast, "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, et un chiffre.");
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL_}/users/invite/complete`, {
        token: inviteToken,
        password,
        first_name: firstName,
        last_name: lastName,
      });
      showSuccessToast(toast, "Votre inscription a été complétée avec succès.");
      navigate('/Connexion');
    } catch (error) {
      showErrorToast(toast, "Une erreur est survenue lors de la complétion de l'invitation.");
      console.error("Une erreur est survenue lors de la complétion de l'invitation :", error);
    }
  };

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    accord,
    setAccord,
    handleCompleteInvitation,
  };
};
