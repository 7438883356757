import React from 'react';
import FicheContact from '../components/Contact/FicheContact';
const ContactPage = () => { 
    return (
        <>
        <FicheContact firstName="Benoit" lastName="Cappe" tel="07.79.82.87.95" email="benoit.cappe@santerne.fr" company="Santerne Fluides"/>
        <FicheContact firstName="Marc Antoine" lastName="Bossaert" tel="06.16.47.22.83" email="marc-antoine.bosseart@santerne.fr" company="Santerne Fluides"/>
        <FicheContact firstName="Xavier" lastName="Delebarre" tel="06.22.27.30.94" email="xavier.delebarre@santerne.fr" company="Santerne Fluides"/>
        </>
    );
}
export default ContactPage;