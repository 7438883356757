import { useState, useCallback } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";


export const useFetchLycee = (id) => {
  const [lycee, setLycee] = useState([]);
  const [client, setClient] = useState([]);
  const [referents, setReferents] = useState([]);
  const [droit, setDroit] = useState(0);
  const user = useAuth().user;
  const isAdmin = useAuth().isAdmin;

  const fetchLycee = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/lycees/${id}`);
      setLycee(response.data);
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [id]);

const fetchCorrespondant = useCallback(async (idI) => {
    if (!idI) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/lycee/${idI}/correspondant`);
      setClient(response.data.client || []);
      setReferents(response.data.referents || []);


    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, []);

  const recupMail = (obj = []) => {
    const mail = obj.map(p => p.User?.email || '');
    return mail.join(", ");
  }


  const fetchDroit = useCallback(async () => {
    try {
      if (!isAdmin) {
        const perm = await axios.get(`${process.env.REACT_APP_API_URL_}/user/${user.user_id}/lycee/${id}/perm`);
        setDroit(perm.data[0].access_level);
      }
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [id, user, isAdmin]);

  return { lycee, fetchLycee, client, referents, fetchCorrespondant, fetchDroit, droit, recupMail };
};
