import React from "react";
import { Card, CardBody, Stack, Text, Center, Divider, Heading } from "@chakra-ui/react";
import { formatDate } from "../../utils/formatDate";
import InterventionControls from "./InterControl";
import PropTypes from "prop-types";

const DemandeInterventionCard = ({ intervention, isAdmin, fetchInterventions }) => {
  DemandeInterventionCard.propTypes = {
    intervention: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    fetchInterventions: PropTypes.func.isRequired,
  };
  return (
    <Card w="80%" m={5} ml="auto" mr="auto" bgColor={"gray.300"} borderColor={"black"}>
        <CardBody>
          <Center>
            <Heading size="md">Nom : {intervention.intervention_name}</Heading>
          </Center>
          <Stack spacing={2} mt={5} mb={5}>
            <Text ml="auto" mr="auto">Description : {intervention.intervention_description}</Text>
            <Center>
              <Text>Num Devis : {intervention.intervention_numDevis}</Text>
              <Text ml="25%">Num Affaire : {intervention.intervention_numAffaire}</Text>
            </Center>
            <Text ml="auto" mr="auto">Date de début estimée : {formatDate(intervention.intervention_startdate)}</Text>
            <Text ml="auto" mr="auto">Date de fin estimée : {formatDate(intervention.intervention_enddate)}</Text>
          </Stack>
          <Stack spacing={2}>
            <Text ml="auto">État : En attente de validation</Text>
          </Stack>
        </CardBody>
      {isAdmin || intervention.intervention_etat !==-1 ? (
        <>
        <Divider />
        <InterventionControls intervention={intervention} isAdmin={isAdmin} fetchInterventions={fetchInterventions} />
        </>
      ) : null}
    </Card>
  );
};


export default DemandeInterventionCard;
