import React, { useEffect, useState } from 'react';
import { Button, Table, Thead, Tbody, Tr, Th, Td, useToast, Box } from '@chakra-ui/react';
import { DeleteIcon, EmailIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { showSuccessToast } from '../../utils/toastNotifications';

const Invitation = () => {
    const [users, setUsers] = useState([]);
    const toast = useToast();  // Initialize toast

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                if (!process.env.REACT_APP_API_URL_) {
                    throw new Error('API URL is not defined');
                }

                const response = await axios.get(`${process.env.REACT_APP_API_URL_}/userInvite`);
                setUsers(response.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchUsers();
    }, []);

    const handleInvite = async (user) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL_}/users/resend`, {
                email: user.email
            });
            showSuccessToast(toast, "Invitation envoyée avec succès.");
        } catch (err) {
            console.error("Une erreur est survenue lors de l'envoi de l'invitation :", err);
        }
    }

    const handleDelete = async (userId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL_}/users/${userId}`);
            setUsers(users.filter((user) => user.user_id !== userId));
            showSuccessToast(toast, "Utilisateur supprimé avec succès.");
        } catch (err) {
            console.error("Une erreur est survenue lors de la suppression de l'utilisateur :", err);
        }
    }

    return (
        <div>
            <Box boxSize="4xl" mr={"auto"} ml={"auto"} mb={5}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Nom</Th>
                        <Th>Email</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {users.map((user) => (
                        <Tr key={user.user_id}>
                            <Td>{user.first_name}</Td>
                            <Td>{user.email}</Td>
                            <Td>
                                <Button mr={5} 
                                    variant="solid"
                                    onClick={() => handleInvite(user)}
                                >
                                    <EmailIcon/>
                                </Button>
                                <Button  onClick={() => handleDelete(user.user_id)}>
                        
                                    <DeleteIcon/>
                                </Button>

                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            </Box>
        </div>
    );
};

export default Invitation;
