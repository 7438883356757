import { useState, useCallback } from "react";
import axios from "axios";

export const useFetchInterventions = (id) => {
  const [interventions, setInterventions] = useState([]);

  const fetchInterventions = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/lycee/${id}/intervention`);
      setInterventions(response.data);
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [id]);

  const handleInterCreated = (newInter) => {
    setInterventions((prevInter) => [...prevInter, newInter]);
  };

  return { interventions, fetchInterventions, handleInterCreated };
};
