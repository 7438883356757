// Methode pour formater la date
export const formatDate = (dateString)=> {
    if(dateString === null){
      return "Non renseigné";
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

