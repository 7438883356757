import React from "react";
import {
  Box,
  Center,
  Avatar,
  Heading,
  Text,
} from "@chakra-ui/react";

const ProfilePage = ({firstName, lastName, tel, email, company}) => {

  return (
      <Box p="4" maxW="md" borderWidth="1px" borderRadius="md" boxShadow="md" bg="white" mx="auto" mb="8">
        <Center mb={4}>
          <Avatar name={`${firstName} ${lastName}`} /*src={`${imglink}`} */ size="xl" />
        </Center>
        <Center mb={4}>
          <Heading size="lg">
            {firstName} {lastName}
          </Heading>
        </Center>
        <Center mb={2}>
          <Text>{email}</Text>
        </Center>
        <Center mb={2}>
          <Text>{tel}</Text>
        </Center>
        <Center mb={2}>
          <Text>{company}</Text>
        </Center>
      </Box>
  );
};

export default ProfilePage;