import React from 'react';
import { Box, Center, Heading } from '@chakra-ui/react';
import CompleteInvitationForm from './CompleteInvitationForm';

const CompleteInvitationPage = () => {
  return (
    <Box
      maxW="md"
      mx="auto"
      mt="8"
      p="8"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
    >
      <Center mb={5}>
        <Heading>Compléter votre profil</Heading>
      </Center>
      <CompleteInvitationForm />
    </Box>
  );
};

export default CompleteInvitationPage;
