import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Text, Image, Grid, GridItem, Input, Center, Heading } from "@chakra-ui/react";

import Icon_school from "../../assets/Icon_school.svg";
import Icon_loc from "../../assets/Icon_loc.svg";
import Icon_doc from "../../assets/Icon_document.svg";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

const SearchContent = () => {
    const [lycee, setLycee] = useState([]);
    const [docs, setDocs] = useState([]);
    const [secteur, setSecteur] = useState([]);
    const [searchText, setSearchText] = useState('');

    const fetchLycee = async () => {
        const result = await axios(
            `${process.env.REACT_APP_API_URL_}/lycees`,
        );
        setLycee(result.data);
    };
    const fetchSecteur = async () => {
        const result = await axios(
            `${process.env.REACT_APP_API_URL_}/secteurs`,
        );
        setSecteur(result.data);
    }
    const fetchDocs = async () => {
        const result = await axios(
            `${process.env.REACT_APP_API_URL_}/docs_s`,
        );
        setDocs(result.data);
    }

    useEffect(() => {
        fetchSecteur();
        fetchLycee();
        fetchDocs();
    }, []);

    const filteredLycee = lycee.filter(item => 
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.Secteur.secteur_nom.toLowerCase().includes(searchText.toLowerCase()) ||
        item.Secteur.secteur_localisation.toLowerCase().includes(searchText.toLowerCase())
    );

    const filteredSecteur = secteur.filter(item => 
        item.secteur_nom.toLowerCase().includes(searchText.toLowerCase()) || 
        item.secteur_localisation.toLowerCase().includes(searchText.toLowerCase())
    );

    const filteredDocs = docs.filter(item => 
        item.nom.toLowerCase().includes(searchText.toLowerCase()) || 
        item.secteur.toLowerCase().includes(searchText.toLowerCase()) || 
        item.lycee.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <Center display={"flex"} flexDirection={"column"} bg={"blackAlpha.100"} w={'50%'} ml={"auto"} mr={"auto"} paddingBottom={50}>
            <Heading as="h1" size="xl" mb={5} mt={5}>
                Recherche
            </Heading>
            <Input w={'80%'}
                type="text"
                placeholder="Rechercher..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
            <Box mt={5}>
                {filteredLycee.map((lycee) => (
                    <Link key={lycee.lycee_id} to={`/lycee/${lycee.lycee_id}`}>
                        <Grid templateColumns={'50px 100px 100px 50px'} borderBottom='1px' borderColor={'gray.200'}>
                            <GridItem colSpan={1}>
                                <Image src={Icon_school} alt="lycee"/>
                            </GridItem>
                            <GridItem colSpan={2} display={'flex'} flexDirection={"column"} alignItems={"center"}>
                                <Box as='b'>{lycee.name}</Box>
                                <Text> {"Secteur : "+lycee.Secteur.secteur_nom + " - " + lycee.Secteur.secteur_localisation}</Text>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <ChevronRightIcon ml={1} mt={3} boxSize={6}/>
                            </GridItem>
                        </Grid>
                    </Link>
                ))}
                {filteredSecteur.map((s) => (
                    <Link key={s.secteur_id} to={`/secteurs/${s.secteur_id}`}>
                        <Grid templateColumns={'50px 100px 100px 50px'} borderBottom='1px' borderColor={'gray.200'}>
                            <GridItem colSpan={1}>
                                <Image src={Icon_loc} alt="Secteur" />
                            </GridItem>
                            <GridItem colSpan={2} display={'flex'} flexDirection={"column"} alignItems={"center"}>
                                <Text as={"b"}> {s.secteur_nom + " - " + s.secteur_localisation}</Text>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <ChevronRightIcon ml={1} mt={3} boxSize={6}/>
                            </GridItem>
                        </Grid>
                    </Link>
                ))}
                {filteredDocs.map((doc) => (
                    <Link key={doc.lycee_id} to={`/lycee/${doc.lycee_id}`}>
                        <Grid templateColumns={'50px 100px 100px 50px'} borderBottom='1px' borderColor={'gray.200'}>
                            <GridItem colSpan={1}>
                                <Image src={Icon_doc} alt="lycee" />
                            </GridItem>
                            <GridItem colSpan={2} display={'flex'} flexDirection={"column"} alignItems={"center"}>
                                <Box as='b'>{doc.nom}</Box>
                                <Text> {"Secteur : "+doc.secteur}</Text>
                                <Text> {"Lycée : "+doc.lycee}</Text>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <ChevronRightIcon ml={1} mt={3} boxSize={6}/>
                            </GridItem>
                        </Grid>
                    </Link>
                ))}
            </Box>
        </Center>
    );
}

export default SearchContent;
