import { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import PropTypes from "prop-types";

const CreateLyceeDrawer = ({ secteurId, onLyceeCreated }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState('');
  const [secteur_id, setSecteur_id] = useState('');
  const [adress1, setAdress1] = useState('');
  const [adress2, setAdress2] = useState('');
  const [postalcode, setPostalcode] = useState('');
  const [city, setCity] = useState('');
  const toast = useToast();

  const [secteurs, setSecteurs] = useState([]);
  const [loading, setLoading] = useState(false);

  
  
  useEffect(() => {
    const fetchSecteurs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL_}/secteurs`);
        setSecteurs(response.data);
      } catch (error) {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la récupération des secteurs.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchSecteurs();
  }, [toast]);

  const handleCreateLycee = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_}/lycees/`, 
      { name, secteur_id, adress1, adress2, postalcode, city });
      if (response.status === 200) {
        toast({
          title: "Lycée créé",
          description: "Le lycée a été créé avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      onLyceeCreated(response.data);
      onClose();
    } catch (error) {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la création du lycée.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button mt={5} variant="solid" colorScheme="blue" onClick={onOpen}>
        Créer Lycée
      </Button>
      <form>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Création de lycée</DrawerHeader>
            <DrawerBody>
              <FormControl>
                <FormLabel>Nom</FormLabel>
                <Input name="name" value={name} onChange={(e) => setName(e.target.value)} required />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Adresse</FormLabel>
                  <Input 
                    name="adress1" 
                    value={adress1} 
                    onChange={(e) => setAdress1(e.target.value)} 
                    required
                  />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Complément d'adresse</FormLabel>
                <Input 
                  name="adress2" 
                  value={adress2} 
                  onChange={(e) => setAdress2(e.target.value)}  
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Code Postal</FormLabel>
                <Input 
                  name="postalcode" 
                  value={postalcode} 
                  onChange={(e) => setPostalcode(e.target.value)} 
                  required 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Ville</FormLabel>
                <Input 
                  name="city" 
                  value={city} 
                  onChange={(e) => setCity(e.target.value)} 
                  required 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Secteur</FormLabel>
                <Select
                  id="secteur"
                  name="secteur_id"
                  value={secteur_id}
                  onChange={(e) => setSecteur_id(e.target.value)}
                >
                  <option value="">--Choisir un secteur--</option>
                  {loading ? (<option>Chargement...</option>) : (
                    secteurs.map((secteur) => (
                      <option key={secteur.secteur_id} value={secteur.secteur_id}>
                        {secteur.secteur_nom}
                      </option>
                    ))
                  )}
                </Select>
              </FormControl>
            </DrawerBody>
            <DrawerFooter>

              <Button variant="ghost" mr={3} onClick={onClose}>
                Annuler
              </Button>
              <Button colorScheme="blue" onClick={handleCreateLycee}>
                Créer
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </form>
    </>
  );
};

CreateLyceeDrawer.propTypes = {
  secteurId: PropTypes.string.isRequired,
  onLyceeCreated: PropTypes.func.isRequired,
};

export default CreateLyceeDrawer;
