//Récupère l'extension du fichier
const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toLowerCase();
  };

// Vérifie si le fichier est une image
export const isImageFile = (fileName) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const ext = getFileExtension(fileName);
    return imageExtensions.includes(ext);
  };