import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import ResetPasswordForm from "../components/Connexion/ResetPasswordForm";
import { validatePasswordMatch } from "../utils/passwordValidation";
import { showErrorToast, showSuccessToast } from "../utils/toastNotifications";

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validatePasswordMatch(password, confirmPassword))  {
      showErrorToast(toast, "Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_}/users/resetPassword`, {
        token: tokenFromQuery,
        password,
      });

      if (response.status === 200) {
        showSuccessToast(toast, "Votre mot de passe a été réinitialisé avec succès.");
        setPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      showErrorToast(toast, error.response?.data?.message || "Votre demande a expiré, veuillez refaire une demande.");
    }
  };

  return (
    <ResetPasswordForm
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      onSubmit={handleSubmit}
    />
  );
};

export default ResetPasswordPage;
