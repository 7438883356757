import { useState, useCallback } from "react";
import axios from "axios";

export const useFetchStats = (id) => {
  const [accept, setAccept] = useState(0);
  const [refus, setRefus] = useState(0);
  const [pending, setPending] = useState(0);

  const fetchStats = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/lycee/docs_stats/${id}`);
      setAccept(response.data.Accepté);
      setRefus(response.data.Refusé);
      setPending(response.data["En attente"]);
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [id]);

  return { accept, refus, pending, fetchStats };
};
