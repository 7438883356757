import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';

const UserForm = ({ user, setUser }) => {
  return (
    <>
      <FormControl isRequired>
        <FormLabel>Prénom</FormLabel>
        <Input
          value={user.firstName}
          onChange={(e) => setUser({ ...user, firstName: e.target.value })}
          isRequired
        />
      </FormControl>

      <FormControl isRequired mt={5}>
        <FormLabel>Nom</FormLabel>
        <Input
          value={user.lastName}
          onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          isRequired
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          isRequired
        />
      </FormControl>

      <FormControl isRequired mt={5}>
        <FormLabel>Entreprise</FormLabel>
        <Input
          value={user.company}
          onChange={(e) => setUser({ ...user, company: e.target.value })}
          isRequired
        />
      </FormControl>

      <FormControl mt={5}>
        <FormLabel>Fonction</FormLabel>
        <Input
          value={user.fonction}
          onChange={(e) => setUser({ ...user, fonction: e.target.value })}
        />
      </FormControl>
    </>
  );
};

export default UserForm;
