import React, { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import InviteUserForm from '../components/Connexion/InviteUserForm';
import { showErrorToast, showSuccessToast } from '../utils/toastNotifications';

const SignupPage = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    fonction: '',
    admin: false
  });
  const toast = useToast();

  const handleInvite = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL_}/users/invite`, {
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        company: user.company,
        fonction: user.fonction,
        role: user.admin ? 'admin' : 'user',
      });

      showSuccessToast(toast, `Une invitation a été envoyée à ${user.email}.`);
    } catch (error) {
      showErrorToast(toast, error.response?.data?.message || "Une erreur est survenue lors de l'envoi de l'invitation.");
    }
  };

  return (
    <InviteUserForm user={user} setUser={setUser} handleInvite={handleInvite} />
  );
};

export default SignupPage;
