export const showToast = (toast, { title, description, status, duration = 5000, isClosable = true }) => {
    toast({
        title,
        description,
        status,
        duration,
        isClosable,
    });
};

export const showSuccessToast = (toast, description) => {
    showToast(toast, {
        title: 'Succès !',
        description,
        status: 'success',
    });
};

export const showErrorToast = (toast, description) => {
    showToast(toast, {
        title: 'Erreur',
        description,
        status: 'error',
    });
};


export const showInfoToast = (toast, description) => {
    showToast(toast, {
        title: 'Information',
        description,
        status: 'info',
    });
}

export const showWarningToast = (toast, description) => {
    showToast(toast, {
        title: 'Attention',
        description,
        status: 'warning',
    });
}