import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import PropTypes from "prop-types";
import { isTokenExpired } from "../utils/isTokenExpired";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useLocalStorage("user", null);
  const [isLogged, setIsLogged] = useState(() => {
    const token = localStorage.getItem("token");
    return token && !isTokenExpired();
  });
  const [isAdmin, setIsAdmin] = useState(() => {
    return user?.role === "admin" || user?.role === "SA";
  });

  const logout = useCallback(() => {
    setAuth(null);
    setIsLogged(false);
    setUser(null);
    setIsAdmin(false);
    localStorage.removeItem("token"); // Supprimer le token lors de la déconnexion
  }, [setUser]);
  
  // Vérification de l'expiration du token lors du montage du composant
  useEffect(() => {
    const checkAuthStatus = () => {
      if (isTokenExpired()) {
        logout(); // Déconnexion si le token est expiré
      } else if (user) {
        setAuth(user);
        setIsLogged(true);
        setIsAdmin(user.role === "admin" || user.role === "SA");
      }
    };

    checkAuthStatus();
  }, [logout, user]);

  const login = useCallback((user) => {
    if (!isTokenExpired()) {
      setAuth(user);
      setIsLogged(true);
      setUser(user);
      setIsAdmin(user.role === "admin" || user.role === "SA");
    } else {
      logout(); // Déconnexion immédiate si le token est déjà expiré
    }
  }, [logout, setUser]);

  const contextValue = useMemo(() => ({
    auth,
    setAuth,
    isLogged,
    setIsLogged,
    login,
    logout,
    user,
    setUser,
    isAdmin,
  }), [auth, isLogged, login, logout, user, setUser, isAdmin]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth doit être utilisé dans un AuthProvider");
  }
  return context;
}
