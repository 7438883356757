import { useState } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, useToast } from "@chakra-ui/react";
import axios from "axios";
import PropTypes from "prop-types";
import {useNavigate } from "react-router-dom";

const SuppressionSecteurModal = ({ secteurId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      // Appel à l'API pour supprimer le secteur
      await axios.delete( `${process.env.REACT_APP_API_URL_}/secteurs/${secteurId}`);
      // Traitement supplémentaire après suppression réussie
      toast({
        title: "Succès !",
        description: "Le secteur a bien été supprimé.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(`/`);
      onClose();

      

    } catch (error) {
      toast({
        title: "Erreur",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Une erreur est survenue lors de la suppression du secteur:", error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Button mt={5} variant="solid" colorScheme="red" onClick={onOpen}>
        Supprimer Secteur
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation de suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer ce secteur ?
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Annuler
            </Button>
            <Button colorScheme="red" onClick={handleDelete} isLoading={isLoading}>
              Supprimer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

SuppressionSecteurModal.propTypes = {
  secteurId: PropTypes.string.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
};

export default SuppressionSecteurModal;
